import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  useFormGroup, Alert, PageSection, useRouter,
} from '@tripledotstudios/react-core';
import { Button } from 'react-bootstrap';

import { useAppData } from '@hooks';
import {
  LabeledSwitch, DiffWrapper, DiffProvider, FloatingLabeledSelect, LabeledSelectWithLink,
} from '@components/resource';
import { ParametersForm } from '@components/dynamic_parameters';
import { EventsRoutes } from '@pages/routes';

import ConfigurationObjectives from './ConfigurationObjectives';
import RecurrenceSettings from './RecurrenceSettings';
import ActivitiesDurationSummary from '../../ActivitiesDurationSummary';
import useEventsParameterSetsOptions from '../../parameter_sets/queries';
import useEventsActivitySetsOptions from '../../activity_sets/queries';

const activitySetUsageOptions = [
  { value: false, label: 'Same across all occurrences' },
  { value: true, label: 'Activity set' },
];
const parameterSetUsageOptions = [
  { value: false, label: 'Same across all occurrences' },
  { value: true, label: 'Parameter set' },
];

export default function ConfigurationVariant({
  currentAttributes,
  configurationOptions,
  configurationTypesOptions,
  defaultVariantAttributes,
  segments,
  type,
  newType,
  isBaseConfigView,
  openBaseView,
  recurring,
}) {
  const { watch, setValue } = useFormContext();
  const { generateName } = useFormGroup();
  const { query } = useRouter();
  const { applicationId } = query;
  const firstRenderRef = useRef(true);

  const assetBundlesTypes = [useAppData().enums.AssetBundleTypes.HAWK_EVENT];
  const eventEnabled = watch(generateName('enabled'));
  const useParameterSet = watch(generateName('useParameterSet'));
  const useActivitySet = watch(generateName('useActivitySet'));
  const activitySetId = watch(generateName('activitySetId'));
  const { data: parameterSetOptions, isLoading: parameterSetsLoading } = useEventsParameterSetsOptions(type?.id);
  useEffect(() => {
    if (!useParameterSet) { setValue(generateName('parameterSetId'), null); }
  }, [useParameterSet]);
  const { data: activitySetOptions, isLoading: activitySetsLoading } = useEventsActivitySetsOptions(type?.id);
  useEffect(() => {
    if (!useActivitySet) { setValue(generateName('activitySetId'), null); }
  }, [useActivitySet]);
  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
    } else {
      setValue(generateName('activitySetId'), null);
      setValue(generateName('parameterSetId'), null);
    }
  }, [newType?.id]);

  const activitySetOption = activitySetId ? activitySetOptions?.find((o) => o.value === activitySetId) : null;

  if (currentAttributes?.isDefaultVariant && !isBaseConfigView) {
    return (
      <Alert variant="warning">
        <div className="ms-2 d-flex align-items-center">
          <div className="flex-grow-1">
            <div>
              Control configuration is based on the base config.
            </div>
            Any changes done in the base config will be reflected in the Control of experiment.
          </div>
          <Button variant="link" className="text-nowrap" onClick={openBaseView}>Go to Base Config</Button>
        </div>
      </Alert>
    );
  }

  const activities = currentAttributes?.configurationObjectivesAttributes || [];
  const resolvedType = newType || type;

  return (
    <DiffProvider baseAttributes={defaultVariantAttributes} currentAttributes={currentAttributes}>
      <DiffWrapper for="enabled">
        <LabeledSwitch
          name="enabled"
          label="Serve the configuration to players"
          sizes={{
            xxl: [3, 4], xl: [4, 4], lg: [4, 4], md: [6, 4], sm: [10, 2], xs: [10, 2],
          }}
        />
      </DiffWrapper>
      {eventEnabled && resolvedType && (
        <>
          {recurring && <RecurrenceSettings activities={activities} activitySetOption={activitySetOption} />}
          <PageSection title="Parameters">
            {recurring && (
              <DiffWrapper for="useParameterSet">
                <FloatingLabeledSelect
                  name="useParameterSet"
                  className="mb-2"
                  options={parameterSetUsageOptions}
                  disabled={currentAttributes.occurrencesExist}
                />
              </DiffWrapper>
            )}
            {useParameterSet
              ? !parameterSetsLoading && (
                <DiffWrapper for="parameterSetId">
                  <LabeledSelectWithLink
                    label="Parameter set"
                    name="parameterSetId"
                    isFloating
                    buildLinkUrl={(id) => EventsRoutes.ParameterSets.editPath({ applicationId, id })}
                    options={parameterSetOptions}
                    disabled={currentAttributes.occurrencesExist}
                  />
                </DiffWrapper>
              ) : (
                <ParametersForm
                  newSourceEntity={newType}
                  sourceEntity={type}
                  DiffWrapper={DiffWrapper}
                  allowedAssetBundlesTypes={assetBundlesTypes}
                  entityName="Events Type"
                />
              )}
          </PageSection>
          <PageSection title="Activities">
            {recurring && (
              <DiffWrapper for="useActivitySet">
                <FloatingLabeledSelect
                  name="useActivitySet"
                  className="mb-2"
                  options={activitySetUsageOptions}
                  disabled={currentAttributes.occurrencesExist}
                />
              </DiffWrapper>
            )}
            {useActivitySet
              ? !activitySetsLoading && (
                <DiffWrapper for="acitivySetId">
                  <LabeledSelectWithLink
                    label="Activity set"
                    name="activitySetId"
                    isFloating
                    buildLinkUrl={(id) => EventsRoutes.ActivitySets.editPath({ applicationId, id })}
                    options={activitySetOptions}
                    disabled={currentAttributes.occurrencesExist}
                  />
                </DiffWrapper>
              ) : (
                <>
                  <ConfigurationObjectives
                    maxActivities={resolvedType.maxActivities}
                    objectiveConfigurationsOptions={configurationOptions}
                    objectiveConfigurationTypesOptions={configurationTypesOptions}
                    segments={segments}
                    recurring={recurring}
                  />
                  {recurring && <ActivitiesDurationSummary activities={activities} />}
                </>
              )}
          </PageSection>
        </>
      )}
    </DiffProvider>
  );
}
