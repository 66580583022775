import React from 'react';
import { useListQuery } from '@hooks';
import { EventsRoutes } from '@pages/routes';
import { Row, Col } from 'react-bootstrap';
import { without } from 'lodash';

import { Form } from '@components';
import { Input, LabeledInput, LabeledSwitch } from '@components/resource';

export default function AudienceGroupFrom({ resource, ...rest }) {
  const { data: typesResponse, isLoading: typesLoading } = useListQuery({ request: EventsRoutes.Types.indexRequest });
  if (typesLoading) return null;

  const types = typesResponse.items;

  return (
    <Form.Resource resource={resource} {...rest}>
      {({ setValue, watch }) => {
        const enabledTypes = watch('eventTypeIds');
        return (
          <Row>
            <Col>
              <Input type="hidden" name="enabled" value="true" />
              <LabeledInput label="Name" name="name" disabled />
              <LabeledSwitch label="Shop" name="show" />
              <LabeledSwitch label="Campaigns" name="campaigns" />
              <LabeledSwitch label="Piggy Banks" name="piggyBanks" />
              <LabeledSwitch label="Events" name="events" />
              <div className="ms-3">
                {types.map((type) => (
                  <LabeledSwitch
                    label={type.name}
                    name={`eventType_${type.id}`}
                    checked={enabledTypes.indexOf(type.id) > -1}
                    onChange={(e) => {
                      setValue(
                        'eventTypeIds',
                        e.target.checked ? [...enabledTypes, type.id] : without(enabledTypes, type.id),
                      );
                    }}
                  />
                ))}
              </div>
            </Col>
          </Row>
        );
      }}
    </Form.Resource>
  );
}
