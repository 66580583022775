import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { Alert } from 'react-bootstrap';

import { Button } from '@components';
import { FormGroup, PageSection } from '@tripledotstudios/react-core';
import { LabeledInput } from '@components/resource';

export default function Leagues({ readOnly }) {
  const { fields, append, remove } = useFieldArray({ name: 'leagues' });

  return (
    <PageSection title="Leagues Settings">
      {fields.map((item, index) => (
        item._destroy || (
          // eslint-disable-next-line react/no-array-index-key
          <FormGroup key={`${item.id}.${index}`} name={`leagues.${index}`}>
            <div className="d-flex mb-1">
              <div className="flex-fill w-100">
                <LabeledInput label={`League tier ${index + 1}`} name="name" />
              </div>
              {readOnly || (
                <Button.Delete
                  className="mt-1"
                  title="Remove"
                  onClick={() => remove(index)}
                />
              )}
            </div>
          </FormGroup>
        )
      ))}

      <Alert variant="warning">
        Once Leagues are configured, only name of the league tier can be changed, but number of leagues will be fixed.
      </Alert>
      {readOnly || <Button.Add className="mt-2" onClick={() => append({ name: '' })} />}
    </PageSection>
  );
}
