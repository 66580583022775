import { PageSection, useFormGroup, FormGroup } from '@tripledotstudios/react-core';

import { useExtendedFieldArray } from '@hooks';
import {
  LabeledInput, Label, LabeledSelect, Input,
} from '@components/resource';
import { InUse } from '@/components';
import useEventsClientLeaderboardsOptions from '../../client_leaderboards/queries';

export default function Items() {
  const { generateName } = useFormGroup();
  const formGroupName = generateName('itemsAttributes');

  const { fields } = useExtendedFieldArray({ name: formGroupName, keyName: 'key' });

  const { data: clientLeaderboards, isLoading: isLeaderboardsLoading } = useEventsClientLeaderboardsOptions();

  if (isLeaderboardsLoading) {
    return null;
  }

  return (
    <>
      <Label label={<span className="h4">Leagues</span>} />
      {fields.map((field, index) => (
        <FormGroup key={[field.key, index].join('_')} name={`${formGroupName}.${index}`}>
          <PageSection title={`${field.tierName}`}>
            <Input hidden name="tierNumber" />
            <LabeledSelect
              label="Client leaderboard"
              name="clientLeaderboardId"
              options={clientLeaderboards}
              formatOptionLabel={InUse.AvailabilityStateOptionLabelFormat}
            />
            <LabeledInput label="Promotion position" name="promotionPosition" type="number" />
            <LabeledInput label="Demotion position" name="demotionPosition" type="number" />
          </PageSection>
        </FormGroup>
      ),
      )}
    </>
  );
}
