import { Form, InUse } from '@components';
import { FloatingLabeledInput } from '@components/resource';
import Items from './form/Items';

export default function RaceForm(props) {
  return (
    <Form.Resource {...props}>
      <Form.ControlsLayout lg={2} xl={2} xxl={2} className="px-1">
        <FloatingLabeledInput label="Name" name="name" />
        <InUse.AvailabilityStateFormGroup
          floating
          readOnly
          tooltipTextOverride="Availability is inherited from the event or activity set where it's used.
          If it's used by multiple entities, the Live availability will have priority"
        />
      </Form.ControlsLayout>
      <Items />
    </Form.Resource>
  );
}
